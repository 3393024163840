@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();


$portal-level: mat.m2-define-typography-level(
	$font-family: $font-family-base,
	$font-weight: 400,
	$font-size: 14px,
	$line-height: $line-height-base,
	$letter-spacing: normal,
);

$portal-typography: mat.m2-define-typography-config(
	$font-family: $font-family-base,
	$body-1: $portal-level,
);

$portal-theme: mat.m2-define-light-theme((
	typography: $portal-typography,
));


@include mat.all-component-themes( $portal-theme );
// @include mat.button-typography( $portal-typography );


/*
------------------------------------------------------
- ANGULAR MATERIAL OVERRIDDEN STYLES
------------------------------------------------------
*/

// Fix snackbar jitter issue when modal open/closes
body.os_windows {
	&:not(.has-no-scrollbar).modal-open, &.has-no-scrollbar {
		.cdk-overlay-pane {
			margin-left: -$scrollbar-width;
		}
	}
}

// Snackbar
.mdc-snackbar.mat-mdc-snack-bar-container {
	margin-bottom: _rem(24px);

	// Small snackbar width
	&.small-width .mdc-snackbar__surface {
		min-width: _rem(280px);
	}

	// Icon center aligned with template
	&.icon-aligned .snackbar-content-wrapper {
		display: flex;
		align-items: center;

		.snackbar-icon {
			bottom: 0
		}
	}
}
